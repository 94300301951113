.n7-footer {
    &__content {
        // display: unset;
        flex-wrap: wrap;
        padding-top: unset;
    }

    .n7-footer__column:nth-child(1) {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        margin-bottom: 30px;
        // background-color: #ffffff;

        img {
            height: 80px;
        }
    }
}