/**
 * HOMEPAGE
 *
 * Layout for homepage
 */

.mr-home {

    .n7-hero {
        background-position: unset !important;
        padding-bottom: unset;

        &__text p {
            color: black;
        }
    }

    .n7-hero.has-background-image:after {
        background-color: rgba(247, 246, 242, 0.8) !important;
    }

    .mr-layout__collection {
        background-color: $color-sepia;

        a {
            box-shadow: 0px 0px 6px 2px #0000006b;
        }

        .mr-items-preview .n7-inner-title__title {
            border-right: 1px solid $color-gray-05;
        }

        // Titles
        .n7-inner-title__title,
        .n7-inner-title__subtitle {
            color: $color-gray-05;
        }

        .n7-item-preview.is-vertical.has-image .n7-item-preview__image, .n7-item-preview.is-vertical.has-color .n7-item-preview__image {
            margin-bottom: 0;
        }

        .n7-item-preview__content {
            background-color: #ffffff;
            padding: 10px;
            min-height: 110px;
            // display: flex;
            // align-items: center;

            h1, p {
                font-family: 'Times New Roman', Times, serif;
            }
        }
        // .n7-item-preview__content::before {
        //     content: "";
        //     background-image: url('../../assets/logo_mambrino.jpg');
        //     background-size: cover;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     width: 30%;
        //     aspect-ratio: 1 / 1;
        // }
        // .n7-item-preview__title-text {
        //     // flex: 2;
        //     width: 70%;
        //     padding-left: 5px;
        // }

        // Cards
        .n7-item-preview.is-overlay.has-image .n7-item-preview__image,
        .n7-item-preview.is-overlay.has-image .n7-item-preview__image:after {
            // border-radius: 15px;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .n7-item-preview.is-overlay.has-image .n7-item-preview__image:after {
            // height: 50px;
            // top: unset;
        }

        .n7-item-preview__image {
            border-radius: 0px;
            // box-shadow: 0px 0px 6px 2px #0000006b;
        }
    }

    // Buttons
    .n7-btn {
        border-color: white;
        border-radius: 30px;
        background-color: transparent;
    }
    .n7-btn:hover {
        background-color: white;
        color: $color-main;
    }
}

// MEDIA QUERY
@media all and (max-width: $breakpoint-smartphone-portrait) {
    .mr-home {
        .mr-layout__collection {
            .n7-item-preview.is-vertical.has-image .n7-item-preview__image {
                height: 600px;
            }
    
            .n7-item-preview__title {
                font-size: 25px;
            }
            .n7-item-preview__text {
                font-size: 20px;
            }
        }
    }
}